$(document).ready(function(){
 		$('.close, .open').click(function() {
            $('.close, .open, .mobile-menu').toggle();
        });
        $('.open').click(function() {
            $('.mobile-nav').css({
            	"position": "fixed",
    			"width": "100%",
    			"z-index": "10000",
                "top": "0px"
            });
        });
        $('.close').click(function() {
            $('.mobile-nav').css({
            	"position": "relative",
    			"width": "auto",
    			"z-index": "10000"
            });
        });

        $(document).on('click','.open_modal,.close_btn',function(){
            $('.fixed-overlay').toggle();
        });

        $(document).on('click','.prices_menu_item',function(){
            $('.prices_menu_item').css('border', 'none');
            $(this).css({'border-bottom': '3px solid #FF5574'} );
            if ($(this).data('catid') == 'all') {
                $('.price_cell').css('display', 'block');
            }
            else {
                $('.price_cell').css('display', 'none');
                $('.price_cell[data-category=' + $(this).data('catid') + ']').css('display', 'block');
            }
        });


        $(document).on('click','.project_proud',function(){
            $('.project_proud').css('border', 'none');
            $(this).css({'border-bottom': '3px solid #FF5574'} );
            if ($(this).data('catid') == 'all') {
                $('.portfolio_cell').css('display', 'block');
            }
            else {
                $('.portfolio_cell').css('display', 'none');
                $('.portfolio_cell[data-category=' + $(this).data('catid') + ']').css('display', 'block');
            }
        });


        $(document).on('click','.scroll-to-top',function(){
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });



        ///////////////////////////////////////////

        $(document).on('click', '.delete_img', function(e) {
            var imgcategory = $(this).data('category');
            var deleteimg = $(this).data('img');
            var deleteid = $(this).data('id');
            console.log(imgcategory);
            console.log(deleteimg);
            console.log(deleteid);
        $.ajaxSetup(
              {
                headers:{
                  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
              }
            );
        $.ajax({
          method: 'POST',
          url: '/ajaxdeleteimg',
          // data: data+'&url='+window.location.pathname,
          data: {'imgcategory':imgcategory, 'deleteimg':deleteimg, 'deleteid':deleteid},
          statusCode: {
            404: function() {
              alert( "Ошибка отправки, свяжитесь с менеджером!" );
            }
          },
          complete: (function(data) { 


             if(data.responseText == 'ok') {
              /* Перезагружаем страницу */
                location.reload();
            }
            else {
              /* TODO: Возвращаем к форме */
              console.log('ERROR');
            }

            
          }),
        });
        e.preventDefault();
        });

        ///////////////////////////////////////////




});